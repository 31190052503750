//import logo from '@Assets/Images/logo.png';
import './App.css';

import React from 'react';

import { ParallaxProvider, useParallax } from 'react-scroll-parallax';



import useViewport from '@Utils/UseViewport';


import mainBG from "@Assets/Images/BGS_01.png";


import Header from '@Components/Header_MO';
import HeadCarousel from '@Pages/MO/HeadCarousel';
import Head from '@Pages/MO/Head';
import NewSection1 from '@Pages/MO/NewSection1';
import NewSection2 from '@Pages/MO/NewSection2';
import NewSection3 from '@Pages/MO/NewSection3';

import Section1 from '@Pages/MO/Section1';
import Section2 from '@Pages/MO/Section2';
import Section3 from '@Pages/MO/Section3';
import Section4 from '@Pages/MO/Section4';
import Section5 from '@Pages/MO/Section5';
import Section6 from '@Pages/MO/Section6';
import Section7 from '@Pages/MO/Section7';
import ContactUsSection from '@Pages/MO/ContactUs';

function App() {


  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { width: windowWidth, height: windowHeight } = useViewport();
  const { ref: cardRef } = useParallax({ speed: 44 });
  const { ref: mainRef } = useParallax({ speed: 0 });

  const sec1Ref = React.useRef(null);
  const sec2Ref = React.useRef(null);
  const sec3Ref = React.useRef(null);


  const scrollToSec = (secId) => {
    switch (secId) {
      case 'top':
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        break;
      case 1:
        window.scrollTo({
          top: sec1Ref.current.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
        break;

      case 2:
        window.scrollTo({
          top: sec2Ref.current.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
        break;

      case 'bottom':
        window.scrollTo({
          top: document.body.scrollHeight - 120,
          left: 0,
          behavior: 'smooth'
        });
        break;
      default:
        break;
    }
  }

  return (
    <>


      <Header pageIndex={currentIndex} setPageIndex={setCurrentIndex} scrollToSec={scrollToSec} />
      <div style={{ width: '100%', zIndex: 18 }}>
        <Head link1Action={() => { scrollToSec(2) }} link2Action={() => { scrollToSec('bottom') }} />
      </div>

      <div ref={mainRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundSize: "100% 100%", marginTop: -64, paddingTop: 96 }}>
        <div ref={cardRef}
          style={{
            marginTop: -400,
            zIndex: 20,
            width: '92%',
            borderRadius: 16,
            boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            overflow: 'hidden'
          }}>
          <div style={{ height: 60 }} />
          <div style={{
            width: '100%',
            //height: 810,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <div style={{ position: 'relative', top: 380 }} ref={sec1Ref} />
            <NewSection1 />
            <div style={{ height: 48 }} />
          </div>

          <div style={{
            width: '100%',
            //height: 810,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "#F0F0F0"
          }}>
            <div style={{ position: 'relative', top: 120 }} />
            <NewSection2 />
          </div>
          <div style={{ height: 60 }} />
          <div style={{
            width: '100%',
            //height: 810,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <div style={{ position: 'relative', top: 300 }} />
            <NewSection3 />
            <div style={{ height: 48 }} />
          </div>

          <div style={{
            width: '100%',
            //height: 810,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: "#F0F0F0"
          }}>
            <div style={{ position: 'relative', height: 48 }} />
            <Section7 />
            <div style={{ height: 48 }} />
          </div>
          <div style={{ height: 60, width: '100%', backgroundColor: "#F0F0F0" }} />
        </div>
        <div style={{ height: 48 }} />
        <div style={{ flexDirection: 'column', width: "72%", display: 'flex', alignItems: 'center', justifyContent: 'center', transform: "translate(0px,-100px)", zIndex: 19 }}>
          <ContactUsSection />
        </div>
        {/* <div style={{ height: 12 }} /> */}
        <div  >
          <a href="https://beian.miit.gov.cn"
            target="_blank"
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              fontFamily: 'sans-serif',
              color: '#909090',
              marginBottom: 12,
            }}>辽ICP备2021003615号-1</a>
        </div>
      </div>
    </>
  );
}

export default App;
