import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';



import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SettingsIcon from '@mui/icons-material/Settings';
import GradingIcon from '@mui/icons-material/Grading';
import BiotechIcon from '@mui/icons-material/Biotech';

import carouseImg1 from '@Assets/Images2.0/L2.jpg';

const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');




const ContentBlock = (props) => {
  return (
    <div style={{
      //display: "flex",
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 200,
      textAlign: 'center',
      fontFamily: 'SourceHanSans',
      userSelect: 'none',
      marginBottom: 20
    }} >

      <div style={{
        flexGrow: 1
      }}>
        <div style={{ userSelect: 'none', cursor: 'pointer' }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: "translate(48px,0px)",
            height: 64, width: 64, borderRadius: 32, marginBottom: 20,
            fontSize: 24,
            backgroundColor: pallette.smokeBlue,
          }}>
            {props.icon}
          </div>
        </div>

        <div style={{
          fontSize: 16,
          textShadow: `1px 1px 1px #303030A0`,
          marginBottom: 18,
          textAlign: 'center'
        }}>
          {props.title}
        </div>
        <div style={{
          marginLeft: 24,
          fontSize: 11,
          fontFamily: 'sans-serif',
          color: '#909090',
          lineHeight: 1.8,
          textAlign: 'left',
        }}>
          {props.text}
        </div>
      </div>
    </div>
  )
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const carouselContainerStyle = {
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const h1Style = {
    display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
    fontFamily: 'SourceHanSans', fontSize: 32, fontWeight: 700,
    textShadow: `1px 1px 1px #303030A0`
  }
  const h2Style = {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    //marginTop: 16,
    marginBottom: 24,
    fontFamily: 'sans-serif',
    color: '#909090',
    lineHeight: 1.8,
    userSelect: 'none', cursor: 'pointer'
  }

  return (
    <div
      style={{
        width: windowWidth
      }}>
      <div style={{
        ...carouselContainerStyle,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{ width: 200, height: 200, display: 'flex', justifyContent: 'center' }}>
          <img
            alt="..."
            src={carouseImg1}
            style={{
              width: "100%",
              objectFit: "contain"
            }}
          />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div style={{ ...h1Style, marginBottom: 20 }}>
            赋能高质量教育发展
          </div>
          <div style={{ ...h2Style, textAlign: 'center' }}>
            发挥技术优势，赋能现代教育
            <br />
            创新多样的教学方式、先进可靠的研发技术
            <br />
            提升教学实践参与度，推进教育数字化转型
          </div>
        </div>


      </div>
    </div>
  )
}