
import React from 'react';

import useViewport from '@Utils/UseViewport';
import MaskImageText from '@Components/MaskImageText';

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.css";
import gradient from '@Assets/Images/gradient.jpg';
import background from '@Assets/Images2.0/background.jpg';
import carouselBG1 from '@Assets/Images2.0/background1.jpg';
import carouselBG2 from '@Assets/Images2.0/background2.jpg';
import carouselBG3 from '@Assets/Images2.0/background3.jpg';
import carouselBG4 from '@Assets/Images2.0/background4.jpg';
import carouseImg1 from '@Assets/Images2.0/home/Main.png';
import carouseImg2 from '@Assets/Images2.0/home/Community.png'
import carouseImg4 from '@Assets/Images2.0/home/MuiltiPlatform.png'

export default (props) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const carouselItemStyle = {
    height: 0.65 * windowHeight,
    width: windowWidth,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const carouselContainerStyle = {
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const h1Style = {
    color: 'white',
    textShadow: "4px 4px 4px #30303090",
    fontFamily: 'SansSourceHans',
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 16,
    lineHeight: 1.4
  }
  const h2Style = {
    color: 'white',
    textShadow: "4px 4px 4px #30303090",
    fontFamily: 'SansSourceHans',
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 16,
    lineHeight: 1.6
  }
  const gradientBlockStyle = {
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center'
  }
  const gradientTextStyle = {
    ...gradientBlockStyle,
    WebkitBackgroundClip: 'text',
    color: "transparent",
    textFillColor: 'transparent',
    //textShadow: 'none'
  }
  return (
    <div style={{ ...carouselItemStyle, backgroundImage: `url(${background})` }}>
      <div style={{
        ...carouselContainerStyle,
        backgroundColor: 'rgba(0,0,0,0.3)',
      }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', marginTop: -100 }}>
          <div style={{ ...h1Style, marginBottom: 12 }}>
            装甲车辆虚拟仿真模拟训练系统
          </div>
          <div style={{ ...h2Style, textAlign: 'center' }}>
            虚拟仿真 · 物理模拟 · 数字孪生
            <br />
            立足于教学、工业、军用等多方面需求定制开发
            <br />
            推进信息技术融入实验教学项目
          </div>

        </div>


      </div>
    </div>

  )
}