import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';
import image1 from '@Assets/Images2.0/1.png';
import image2 from '@Assets/Images2.0/2.png';
import image3 from '@Assets/Images2.0/3.png';


const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');

const avatarStyle = {
  height: 300, width: 300, borderRadius: 0, marginBottom: 24,
  objectFit: 'contain',
  objectPosition: 'center', userSelect: 'none', cursor: 'pointer'
}

const h1Style = {
  display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
  fontFamily: 'SourceHanSans', fontSize: 40, fontWeight: 700,
  textShadow: `1px 1px 1px #303030A0`
}

const h2Style = {
  color: "#3C4858",
  fontSize: 24,
  textAlign: 'center',
  fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
}

const pStyle = {
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: 16,
  marginTop: 16,
  fontFamily: 'sans-serif',
  color: "#666666",
  lineHeight: 1.8,
  userSelect: 'none', cursor: 'pointer'
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div
      style={{
        width: windowWidth > 1200 ? (960) : (480)
      }}>
      <div id={'section2'} style={{ position: 'relative', top: -128 }} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image1} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image2} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={image3} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              构造原理
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              操作使用
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              考核评估
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>工业级精细3D建模</><br />
                <>真实物理仿真、材质仿真</><br />
                <>还原真实工作状态与原理</>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>桌面 / VR / AR / MR</><br />
                <>交互式操作、沉浸式体验</><br />
                <>优化简化繁复的操作流程</>
              </>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>针对性定制教学与对应的考核模块</><br />
                <>信息技术融入教学</><br />
                <>满足教学、训练、展示等多方面需求</>
              </>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}