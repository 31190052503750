import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';



import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SettingsIcon from '@mui/icons-material/Settings';
import GradingIcon from '@mui/icons-material/Grading';
import BiotechIcon from '@mui/icons-material/Biotech';

import carouseImg1 from '@Assets/Images2.0/L1.png';

const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');



const carouselContainerStyle = {
  height: '100%',
  width: '100%',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
}
const h1Style = {
  color: "#3C4858",
  fontFamily: 'SansSourceHans',
  fontWeight: 700,
  fontSize: 40,
  marginBottom: 16,
  lineHeight: 1.4
}
const h2Style = {
  color: "#666666",
  fontFamily: 'SansSourceHans',
  fontWeight: 400,
  fontSize: 20,
  marginBottom: 16,
  lineHeight: 1.6,
  textAlign: 'left'
}
const ContentBlock = (props) => {
  return (
    <div style={{
      //display: "flex",
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 420,
      textAlign: 'center',
      fontFamily: 'SourceHanSans',
      userSelect: 'none'
    }} >

      <div style={{
        flexGrow: 1
      }}>
        <div style={{ userSelect: 'none', cursor: 'pointer' }}
        //onClick={() => { window.location.href = props.href }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: "translate(36px,0px)",
            height: 144, width: 144, borderRadius: 72, marginBottom: 24,
            fontSize: 48,
            backgroundColor: pallette.smokeBlue,
          }}>
            {props.icon}
          </div>
        </div>

        <div style={{
          fontSize: 28,
          textShadow: `1px 1px 1px #303030A0`,
          marginBottom: 18,
          textAlign: 'center'
        }}>
          {props.title}
        </div>
        <div style={{
          marginLeft: 24,
          fontSize: 16,
          fontFamily: 'sans-serif',
          color: '#909090',
          lineHeight: 1.8,
          textAlign: 'left',
        }}>
          {props.text}
        </div>
      </div>
    </div>
  )
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div
      style={{
        width: windowWidth > 1200 ? (960) : (480)
      }}>
      <div id={'section1'} style={{ position: 'relative', top: -64 }} />
      <div style={{
        ...carouselContainerStyle,
        display: 'flex',
        flexDirection: 'row'
      }}>
        <div style={{ width: 480, display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
          <div style={{ ...h1Style }}>
            虚拟仿真
          </div>
          <div style={{ ...h2Style, textAlign: 'left' }}>
            沉浸式体验，交互式操作
            <br />
            真实物理仿真，还原现实场景
            <br />
            模块化开发，可扩展性高
          </div>
        </div>
        <div style={{ width: 480, display: 'flex', justifyContent: 'center' }}>
          <img
            alt="..."
            src={carouseImg1}
            style={{
              width: "100%",
              height: "61.8vh",
              maxHeight: "600px",
              objectFit: "contain"
            }}
          />
        </div>
      </div>
    </div>
  )
}