
import React from 'react';

import useViewport from '@Utils/UseViewport';
import MaskImageText from '@Components/MaskImageText';

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.css";
import gradient from '@Assets/Images/gradient.jpg';
import carouselBG1 from '@Assets/Images2.0/background1.jpg';
import carouselBG2 from '@Assets/Images2.0/background2.jpg';
import carouselBG3 from '@Assets/Images2.0/background3.jpg';
import carouselBG4 from '@Assets/Images2.0/background4.jpg';
import image1 from '@Assets/Images2.0/1.png';
import image2 from '@Assets/Images2.0/2.png';
import image3 from '@Assets/Images2.0/3.png';

export default (props) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const carouselItemStyle = {
    height: 0.6 * windowHeight,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const carouselContainerStyle = {
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const h1Style = {
    fontSize: 22,
    textShadow: `1px 1px 1px #303030A0`,
    textAlign: 'center',
    fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
  }
  const h2Style = {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    //marginTop: 16,
    marginBottom: 24,
    fontFamily: 'sans-serif',
    color: '#909090',
    lineHeight: 1.8,
    userSelect: 'none', cursor: 'pointer'
  }
  const gradientBlockStyle = {
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center'
  }
  const gradientTextStyle = {
    ...gradientBlockStyle,
    WebkitBackgroundClip: 'text',
    color: "transparent",
    textFillColor: 'transparent',
    //textShadow: 'none'
  }
  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      interval={5000}
      showStatus={false}
    >
      <div style={{ width: '100%' }}>
        <div style={{
          ...carouselContainerStyle,
        }}>
          <div style={{ height: 60 }}></div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              构造原理
            </div>
            <div style={{ ...h2Style, textAlign: 'center' }}>
              工业级精细3D建模
              <br />
              真实物理仿真、材质仿真
              <br />
              还原真实工作状态与原理
            </div>
          </div>
          <div style={{ width: 150, height: 150, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={image1}
              style={{
                width: "100%",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div style={{
          ...carouselContainerStyle,
        }}>
          <div style={{ height: 60 }}></div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              操作使用
            </div>
            <div style={{ ...h2Style, textAlign: 'center', marginBottom: 8 }}>
              桌面 / VR / AR / MR<br />
              交互式操作、沉浸式体验<br />
              优化简化繁复的操作流程
            </div>

          </div>
          <div style={{ width: 150, height: 150, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={image2}
              style={{
                width: "100%",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div style={{
          ...carouselContainerStyle,
        }}>
          <div style={{ height: 60 }}></div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: "center" }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              考核评估
            </div>
            <div style={{ ...h2Style, textAlign: 'center' }}>
              针对性定制教学与对应的考核模块<br />
              信息技术融入教学<br />
              满足教学、训练、展示等多方面需求
            </div>
          </div>
          <div style={{ width: 150, height: 150, display: 'flex', justifyContent: 'center' }}>
            <img
              alt="..."
              src={image3}
              style={{
                width: "100%",
                objectFit: "contain"
              }}
            />
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </div>
    </Carousel >
  )
}